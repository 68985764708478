import axios from "axios";
// import { useSelector } from "react-redux"; // Import the useSelector hook
const envars = process.env;
console.log("REACT_APP_API_BACKEND",envars)
const API = axios.create({
      baseURL: "https://app.bastionex.net/api/",
    //  baseURL: envars?.REACT_APP_API_BACKEND,
    //baseURL: 'http://127.0.0.1:8000/api/',
});

export default API;
