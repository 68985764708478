import React, { useState } from "react";
import { Button, Form, Tab, Nav } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { WITHDRAW_URL } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

const AccountWithdraw = () => {

  const history = useHistory();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState({ "currency": "INR", "amount": "", "method": "bank", "mobile": "", "account": "", "ifsc": "", "upi": "" });

  const changeDataHandler = (value, field, validationType = "any") => {
    // Perform validation based on the validationType
    let updatedValue = value;

    if (validationType === 'alpha_only') {
      // Allow only alphabetic characters
      updatedValue = value.replace(/[^a-zA-Z]/g, '');
    } else if (validationType === 'number_only') {
      // Allow only numeric characters
      updatedValue = value.replace(/\D/g, '');
    }

    // Update the data state
    setData(prevData => ({
      ...prevData,
      [field]: updatedValue
    }));
  };

  function changeCurrencyHandler(currency) {
    setData(prevData => ({
      ...prevData,
      "currency": currency
    }));
  }

  function changeMethodHandler(method) {
    setData(prevData => ({
      ...prevData,
      "method": method
    }));
  }

  async function withdrawRequestHandler() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let dataTemp = {
        "amount": data.amount,
        "currency": data.currency,
        "payout_method": data.method,
        "mobile": data.mobile,
        "account": data.account,
        "ifsc": data.ifsc,
        "upi": data.upi
      };
      let res = await api.post(WITHDRAW_URL, dataTemp);
      if (res.data.status === "SUCCESS") {
        toast.success("Withdraw request generated");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="screen">
      <div className="main-content bg-light">
        <div className="d-flex align-items-center justify-content-between mb-30">
          <div className="col-6">
            <span class="d-flex align-items-center fw-semibold gap-2" onClick={() => { history.goBack() }}>
              <img src="images/left-arrow.svg" alt="left-arrow"></img>Withdraw
            </span>
          </div>
          <div className="col-6">
            <Tab.Container id="tabs-example" >
              <Nav
                className="d-flex justify-content-end orders-tab"
                variant="tabs"
              >
                <Nav.Item>
                  <Nav.Link eventKey="inr" className={(data.currency === "INR") ? "active" : ""} onClick={() => { changeCurrencyHandler("INR") }}>INR</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="forex" className={(data.currency === "USD") ? "active" : ""} onClick={() => { changeCurrencyHandler("USD") }}>Forex</Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Tab.Content>
                <Tab.Pane eventKey="inr"></Tab.Pane>
                <Tab.Pane eventKey="forex"></Tab.Pane>
              </Tab.Content> */}
            </Tab.Container>
          </div>
        </div>

        <div className="withdraw-model-box text-center bg-white p-3">
          <span class="fw-semibold">Online withdrawal</span>
          <div className="d-flex align-items-center justify-content-center paymet-method-type">
            <div class="form-check d-flex align-items-center gap-2">
              <input
                class="form-check-input"
                type="radio"
                checked={data.method === "upi"}
                onChange={() => { changeMethodHandler("upi") }}
              />
              <label class="form-check-label" for="flexRadioDefault1">
                <span>
                  <img src="images/upi.svg" alt="upi-icon" onClick={() => { changeMethodHandler("upi") }}></img>
                </span>
              </label>
            </div>
            <div class="form-check d-flex align-items-center gap-2">
              <input
                class="form-check-input"
                type="radio"
                checked={data.method === "bank"}
                onChange={() => { changeMethodHandler("bank") }}
              />
              <label class="form-check-label" for="flexRadioDefault2">
                <span>
                  <img src="images/bank.svg" alt="upi-icon" onClick={() => { changeMethodHandler("bank") }}></img>
                </span>
              </label>
            </div>
          </div>
          <Form>
            <Form.Group className="mb-30" controlId="formGroupPassword">
              <Form.Control
                className="custom-form-control grey-bg"
                size="lg"
                type="text"
                placeholder="Mobile No."
                value={data.mobile}
                onChange={(e) => { changeDataHandler(e.target.value, "mobile", "number_only") }}
              />

            </Form.Group>
            <Form.Group className="mb-30" controlId="formGroupPassword">
              <Form.Control
                className="custom-form-control grey-bg"
                size="lg"
                type="text"
                placeholder="Amount"
                value={data.amount}
                onChange={(e) => { changeDataHandler(e.target.value, "amount", "number_only") }}
              />

            </Form.Group>
            {(data.method === "upi") && <Form.Group className="mb-30" controlId="formGroupPassword">
              <Form.Control
                className="custom-form-control grey-bg"
                size="lg"
                type="text"
                placeholder="UPI ID"
                value={data.upi}
                onChange={(e) => { changeDataHandler(e.target.value, "upi", "ant") }}
              />

            </Form.Group>}

            {(data.method === "bank") && <><Form.Group className="mb-30" controlId="formGroupPassword">
              <Form.Control
                className="custom-form-control grey-bg"
                size="lg"
                type="text"
                placeholder="Account no"
                value={data.account}
                onChange={(e) => { changeDataHandler(e.target.value, "account", "number_only") }}
              />

            </Form.Group>

              <Form.Group className="mb-30" controlId="formGroupPassword">
                <Form.Control
                  className="custom-form-control grey-bg"
                  size="lg"
                  type="text"
                  placeholder="IFSC CODE"
                  value={data.ifsc}
                  onChange={(e) => { changeDataHandler(e.target.value, "ifsc", "any") }}
                />

              </Form.Group></>
            }
            <div className="">
              <Button className="signin-btn w-100" onClick={withdrawRequestHandler}>Submit</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AccountWithdraw;
