import React, { useState } from "react";
import { Tab, Nav, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DEPOSIT_URL } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

export default function AccountDeposite() {
  const history = useHistory();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState({ "currency": "INR", "amount": "" });

  const changeDataHandler = (value, validationType = "any") => {
    // Perform validation based on the validationType
    let updatedValue = value;

    if (validationType === 'alpha_only') {
      // Allow only alphabetic characters
      updatedValue = value.replace(/[^a-zA-Z]/g, '');
    } else if (validationType === 'number_only') {
      // Allow only numeric characters
      updatedValue = value.replace(/\D/g, '');
    }

    // Update the data state
    setData(prevData => ({
      ...prevData,
      "amount": updatedValue
    }));
  };

  function changeCurrencyHandler(currency) {
    setData(prevData => ({
      ...prevData,
      "currency": currency
    }));
  }

  async function depositRequestHandler() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let dataTemp = {
        "amount": data.amount,
        "currency": data.currency
      };
      let res = await api.post(DEPOSIT_URL, dataTemp);
      if (res.data.status === "SUCCESS") {
        window.location.href = res.data.payload.url;
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="screen">
      <div className="main-content bg-light">
        <div className="d-flex align-items-center justify-content-between mb-30">
          <div className="col-6">
            <span class="d-flex align-items-center fw-semibold gap-2" onClick={() => { history.goBack() }}>
              <img src="images/left-arrow.svg" alt="left-arrow"></img>Deposit
            </span>
          </div>
          <div className="col-6">
            <Tab.Container id="tabs-example" >
              <Nav
                className="d-flex justify-content-end orders-tab"
                variant="tabs"
              >
                <Nav.Item>
                  <Nav.Link eventKey="inr" className={(data.currency === "INR") ? "active" : ""} onClick={() => { changeCurrencyHandler("INR") }}>INR</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="forex" className={(data.currency === "USD") ? "active" : ""} onClick={() => { changeCurrencyHandler("USD") }}>Forex</Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Tab.Content>
                <Tab.Pane eventKey="inr"></Tab.Pane>
                <Tab.Pane eventKey="forex"></Tab.Pane>
              </Tab.Content> */}
            </Tab.Container>
          </div>
        </div>
        <Form>
          <Form.Group className="mb-30" controlId="OnlineDeposite">
            <Form.Label className="custom-label fw-seibold mb-2">
              Online Deposit
            </Form.Label>
            <Form.Control
              className="custom-form-control"
              size="lg"
              type="text"
              placeholder="Amount"
              value={data.amount}
              onChange={(e) => { changeDataHandler(e.target.value, "number_only") }}
            />
          </Form.Group>
          <div className="mt-4">
            <Button className="signin-btn w-100" onClick={depositRequestHandler}>Submit</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

